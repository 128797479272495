<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-6">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h3 class="mb-0">
            {{ lista_campos != "" ? "Editar" : "Criar" }} Vinculo
          </h3>
        </div>
        <div class="card-body">
          <div class="form">
            <div class="form-group row">
              <div class="col-md-12">
                <label class="col-md-12 ">Selecione la especialidad:*</label>
                <treeselect
                  :multiple="false"
                  :options="lista_especialidades"
                  placeholder="Selecione una o mas especialidades..."
                  v-model="form.especialidade_id"
                />
                <treeselect-value :value="form.especialidade_id" />
              </div>
              <div class="col-md-12">
                <label class="col-md-12">Seleccione la sala:*</label>
                <treeselect
                  :multiple="false"
                  :options="lista_sala"
                  placeholder="Selecione una sala..."
                  v-model="form.sala_id"
                />
                <treeselect-value :value="form.sala_id" />
              </div>
              <div class="col-md-12">
                <label class="col-md-12">Seleccione el Doctor:*</label>
                <treeselect
                  :multiple="false"
                  :options="lista_usuarios"
                  placeholder="Seleccione una o mas especialidades..."
                  v-model="form.medico_id"
                />
                <treeselect-value :value="form.medico_id" />
              </div>
              <div class="col-md-12">
                <label class="col-md-12">Descripcion:*</label>
                <input
                  required
                  type="text"
                  class="form-control"
                  v-model="form.descricao"
                  placeholder="Digite a descrição..."
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-12 text-center">
                <button
                  @click="confirm(lista_campos == '' ? 'cria' : 'edita')"
                  class="btn btn-primary"
                  :disabled="verif"
                >
                  Salvar
                  <b-spinner
                    v-show="verif"
                    small
                    variant="dark"
                    type="grow"
                    label="Spinning"
                  ></b-spinner>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { fireAlert } from "@/core/mixins/alertMessageMixin";

export default {
  mixins: [fireAlert],
  data() {
    return {
      form: {
        descricao: "",
        sala_id: "",
        especialidade_id: "",
        medico_id: "",
        status: 1,
      },
      pesquisa: {
        tipo: "nome",
        texto: "",
        filial_id: null,
        perfil_id: 2
      },
      verif: false,
    };
  },
  created() {
    this.listar_requerimentos();
    this.preenxerCampos();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Vinculos Salas" }]);
  },
  computed: {
    lista_usuarios() {
      return this.$store.state.configUsuarios.lista_usuarios;
    },
    lista_especialidades() {
      return this.$store.state.configEmpresa.lista_especialidades;
    },
    lista_sala() {
      return this.$store.state.configEmpresa.lista_sala;
    },
    lista_filials() {
      return this.$store.state.configEmpresa.lista_filials;
    },

    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },
    mensagem_alert() {
      return this.$store.state.configEmpresa.mensagem_alert;
    },
  },
  methods: {
    async confirm(tipo) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação  ` + tipo + ` uma Sala no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") this.create();
            if (tipo == "edita") this.update();
          }
        },
      });
    },
    makeToast(variant = null) {
      this.$bvToast.toast("Salvo com sucesso", {
        title: "Sucesso",
        variant: variant,
        solid: false,
      });
    },
    async create() {
      this.verif = true;
      await this.$store.dispatch("configEmpresa/create_vinculo_sala_especialidade", this.form);
      this.verif = false;

      this.fireAlert({
        ...this.mensagem_alert,
        tipo: "success",
        routeName: "vinculo_sala",
      });
    },
    async update() {
      this.verif = true;
      await this.$store.dispatch("configEmpresa/update_vinculo_sala_especialidade", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert.message,
        routeName: "vinculo_sala",
      });
    },
    async listar_requerimentos() {
      await this.$store.dispatch("configUsuarios/listar_medicos");
      await this.$store.dispatch("configEmpresa/listar_sala");
      await this.$store.dispatch("configEmpresa/listar_especialidades");
    },
    preenxerCampos() {
      if (this.lista_campos != "")
        this.form = {
          id: this.lista_campos.id,
          descricao: this.lista_campos.descricao,
          status: this.lista_campos.status,
          medico_id: this.lista_campos.medico_id,
          sala_id: this.lista_campos.sala_id,
          especialidade_id: this.lista_campos.especialidade_id,
        };
    },
  },
};
</script>
  
  <style scoped>
.lista:hover {
  background-color: rgb(167, 183, 255);
}
</style>